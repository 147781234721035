// @import './breakpoints';
.contact-us-page {
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  // background-attachment: fixed;
  // background-size: cover;
  // background-repeat: repeat-y;
  // height: 100vh;
  height: fit-content;
  overflow: hidden;
  
 padding-bottom:80px;
  font-family: sans-serif;

    .contact-us{
      background-color: rgba(0, 0, 0, 0.5);
      color: rgb(238, 238, 238);
      // font-weight: bold;
      // border: 3px solid #f1f1f1;
      position: relative;
      // top: 230%;
      // left: 50%;
      // transform: translate(-50%, -80%);
      z-index: 2;
      max-width: 60%;
      padding: 50px 20px 50px;
      // text-align: center;
      margin: 50px auto auto;
      border-radius: 15px;

      @media (max-width: $breakpoint-phone) {
          padding: 50px 0 60px;
          max-width: 90%;
      }
    }
    // #horizon {
    //   position: absolute;
    //   width: 160%;
    //   height: 70%;
    //   border-radius: 100%/100%;
    //   background: #038bff;
    //   -webkit-filter: blur(30px);
    //   left: 50%;
    //   bottom: -20%;
    //   margin-left: -80%;
    // }
    // #horizon:before {
    //   content: " ";
    //   position: absolute;
    //   width: 81.25%;
    //   height: 70%;
    //   border-radius: 100%/100%;
    //   background: #51AFFF;
    //   -webkit-filter: blur(30px);
    //   opacity: 0.6;
    //   margin-left: 9.375%;
    // }
    // #horizon:after {
    //   content: " ";
    //   position: absolute;
    //   width: 32%;
    //   height: 20%;
    //   border-radius: 650px/350px;
    //   background: #B0DAFF;
    //   -webkit-filter: blur(30px);
    //   opacity: 0.5;
    //   margin-left: 34%;
    // }
    // #horizon .glow {
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   border-radius: 100%/100%;
    //   background: #215496;
    //   -webkit-filter: blur(200px);
    //   opacity: 0.7;
    //   top: -10%;
    // }
    
    // #earth {
    //   position: absolute;
    //   width: 200%;
    //   height: 100%;
    //   background: black;
    //   border-radius: 100%/100%;
    //   left: 50%;
    //   bottom: -50%;
    //   margin-left: -100%;
    // }
    // #earth:before {
    //   content: " ";
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   border-radius: 100%/100%;
    //   box-shadow: inset 0px 0px 62px 20px rgba(60, 105, 138, 0.85);
    // }
    // #earth:after {
    //   *zoom: 1;
    //   filter: progid:DXImageTransform.Microsoft.gradient(gradientType=1, startColorstr='#FF000000', endColorstr='#FF000000');
    //   background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    //   background-size: 100%;
    //   background-image: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, #000000), color-stop(50%, rgba(0, 0, 0, 0)), color-stop(100%, #000000));
    //   background-image: -moz-linear-gradient(left, #000000 0%, rgba(0, 0, 0, 0) 50%, #000000 100%);
    //   background-image: -webkit-linear-gradient(left, #000000 0%, rgba(0, 0, 0, 0) 50%, #000000 100%);
    //   background-image: linear-gradient(to right, #000000 0%, rgba(0, 0, 0, 0) 50%, #000000 100%);
    //   content: " ";
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   border-radius: 100%/100%;
    // }
    
    #title {
      // position: absolute;
      // font-weight: 300;
      // // top: 25%;
      // // left: 0;
      // // right: 0;
      // // margin-top: -80px;
      // font-size: 90px;
      // font-family: 'Bebas Neue', cursive;
      // font-style:italic;
    
      // text-align: center;
      // letter-spacing: 10px;
      // // padding-left: 20px;
      // background: -webkit-linear-gradient(white, #dbdde0, #38495a,pink);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
      text-align: center;
        // font-weight: 100;
        font-family: 'Bebas Neue', cursive;
        font-size: 100px;
        font-weight: 300;
        font-style: italic;
        color:rgb(228, 220, 220);
        // color: #452F90;
        // text-shadow: 2px 2px darkblue;
        // margin-bottom: 80px;
        letter-spacing: 4px;
        @media (max-width: $breakpoint-phone) {
          font-size: 50px;
          animation: none;
          letter-spacing: 4px;
        }  
    


      animation-duration: 6s;
      animation-iteration-count: infinite;
      animation-timing-function: ease;
      animation-name: animGravity;
    
    }
    
    #subtitle {
      // position: absolute;
      font-weight: 300;
      // top: 75%;
      // left: 50%;
      // right: 0;
      margin: 30px 0;
      font-size: 25px;
      text-align: center;
      letter-spacing: 2px;
    
      //  background-color: rgb(0,0,0); /* Fallback color */
      // background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
      color: white;
      // font-family: century gothic;
      font-family: sans-serif;

      
     
      
      // border: 3px solid pink;
      
      // transform: translate(-50%, -80%);
      // z-index: 2;
      // width: 55%;
      // padding: 20px;
      
    }

    h2{
      text-align:center;
                // color:white;
                color: #ed1865;
                font-size:60px;
                text-transform: uppercase;
                // font-weight: 600;
                font-weight: normal;
                letter-spacing: 2px;
                font-family: 'Bebas Neue', cursive;
                font-style: italic;

                  @media (max-width: $breakpoint-phone) {
                    font-size: 40px;
                    margin: 0 5px 20px;
                  }  
    }

    h3{
      font-size:35px;
      position: relative;
      // top: -20px;
      // right: -20px;
      color:#E1DBE9 ;
      // color: #ed1865;
      // text-shadow: 1px 1px #ACE5EE;
      // margin: 20px;
      font-style: italic;
      text-align: center;
      font-family: 'Bebas Neue', cursive;
      letter-spacing: 2px;
      width: fit-content;
      margin: 50px auto 30px;
      padding: 5px 30px;
      clip-path: polygon(0 0, 100% 0, 100% 70%, 90% 100%, 0 100%);
      background-color: #452F90;
      font-weight: 200;
      @media (max-width: $breakpoint-phone) {
          font-size: 30px;
          // top: -30px;
      }
    }
    // p{
    //   margin-top: 20px;
    //   line-height: 1.5;
    //   color:#fdf5ea;
    //   font-family: 'Roboto', sans-serif;

    //   @media (max-width: $breakpoint-phone) {
    //     font-size: 18px;
    //     // text-align: left;
    //     margin: 20px 0 30px;
    //     padding: 0 20px;
    //   }  
    // }
    p{
      // letter-spacing: 0.3px;
      // text-indent: 0;
      // padding: 0;
      margin: auto;
      margin-top: 10px;
      // margin-left: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      // letter-spacing: 1px;
      letter-spacing: 0;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;

      @media (max-width: $breakpoint-phone){
        font-size: 20px;
        // letter-spacing: 0;
    }

      span{
          color: #ed1865;                ;
          margin-left: 10px;
      }

      svg{
          margin-right: 10px;
      }
  }
    
    // @keyframes animStar {
    //   from {
    //     transform: translateY(0px);
    //   }
    //   to {
    //     transform: translateY(-2000px);
    //   }
    // }
    @keyframes animGravity {
      0% {
        transform: translateY(-26px);
        opacity: 0;
      }
      30%, 80% {
        letter-spacing: 20px;
        // padding-left: 40px;
        transform: translateY(0px);
        opacity: 1;
      }
      92%, 100% {
        letter-spacing: 25px;
        // padding-left: 35px;
        transform: translateY(-4px);
        opacity: 0;
      }
    }
    // @keyframes animDont {
    //   0%	, 15% {
    //     transform: translateY(-26px);
    //     opacity: 0;
    //   }
    //   35%, 80% {
    //     transform: translateY(0px);
    //     opacity: 1;
    //   }
    //   92%, 100% {
    //     transform: translateY(-4px);
    //     opacity: 0;
    //   }
    // }
    // @keyframes animLet {
    //   0%	, 25% {
    //     transform: translateY(-26px);
    //     opacity: 0;
    //   }
    //   45%, 80% {
    //     transform: translateY(0px);
    //     opacity: 1;
    //   }
    //   92%, 100% {
    //     transform: translateY(-4px);
    //     opacity: 0;
    //   }
    // }
    // @keyframes animGo {
    //   0%	, 35% {
    //     transform: translateY(-26px);
    //     opacity: 0;
    //   }
    //   55%, 80% {
    //     transform: translateY(0px);
    //     opacity: 1;
    //   }
    //   92%, 100% {
    //     transform: translateY(-4px);
    //     opacity: 0;
    //   }
    // }
    
  }
  
  