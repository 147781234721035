// @import './breakpoints';

// .main-content{
//     // position: relative;
//     // overflow: hidden;
// }

// .background-foreground-img{
//     position: fixed;
//     width: 100vw;
//     height: 100vh;
//     left: 0;
//     top: 0;
//     display: none;

//     @media (max-width: $breakpoint-phone) {
//         display: none;
//     }
// }

// .upper-extra-cover{
//     position: fixed;
//     top: 0;
//     left: 0;
//     background: url(../imgs/backgrounds/foreground2.jpg);
//     background-position: top;
//     background-repeat: no-repeat;
//     background-size: cover;  
//     .upper-extra-back{
//         // height: 40vh;
//         width: 100vw;

//         animation: top-background 2s ease-in-out  1 both;        
//         @keyframes top-background{
//             from{
//                 height: 40vh;
//             }
//             to{
//                 height: 0;
//             }
//         }
//     }
// }



.background-outline-top{
    opacity: 0.95;
    position: fixed;
    width: 100vw;
    // height: 120px;
    height: 16vh;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: #28a5bf;
    // clip-path: polygon(0 0,100% 0, 100% 72px, 93% 72px, 90% 50px, 65% 50px, 62% 72px, 38% 72px, 35% 50px, 350px 50px, 300px 95px, 20px 95px, 0 120px);
    // clip-path: polygon(0 0,100% 0, 100% 60%, 93% 60%, 90% 41.6%, 65% 41.6%, 62% 59.5%, 38% 59.5%, 35% 41%, 350px 41%, 300px 78%, 20px 78%, 0 100%);

    // clip-path: polygon(0 0,100% 0, 100% 9.6vh, 93% 9.6vh, 90% 6.65vh, 65% 6.65vh, 62% 9.52vh, 38% 9.52vh, 35% 6.56vh, 50vh 6.56vh, 40vh 12.48vh, 4vh 12.48vh, 0 16vh);

    clip-path: polygon(0 0,100% 0, 100% 9.56vh, 90% 9.56vh,87% 6.56vh,50vh 6.56vh, 40vh 12.48vh, 4vh 12.48vh, 0 16vh);




    // animation: top-outline-background 2s ease-in-out  1 both;

    @keyframes top-outline-background{
        0%{
            clip-path: polygon(0 0,100% 0, 100% 485px, 93% 485px, 90% 455px, 65% 455px, 62% 485px, 38% 485px, 35% 455px, 450px 455px, 350px 520px, 20px 520px, 0 530px);
            height: 530px;
        }
        100%{
            clip-path: polygon(0 0,100% 0, 100% 72px, 93% 72px, 90% 50px, 65% 50px, 62% 72px, 38% 72px, 35% 50px, 350px 50px, 300px 95px, 20px 95px, 0 120px);
            height: 120px;
        }
    }     

    // @media (max-width: $breakpoint-tablet) {
    //     // clip-path: polygon(100% 0, 100% 62px, 230px 62px, 190px 77px, 20px 77px, 0 92px, 0 0);
    //     clip-path: polygon(0 0,100% 0, 100% 9.6vh, 40vh 9.6vh, 32vh 12.48vh, 5vh 12.48vh, 0 16vh);
    // }

    @media (max-width: $breakpoint-phone) {
        // display: none;
        // width: auto;
        // background-color: #28a5bf;
        // clip-path: polygon(100% 0, 100% 62px, 230px 62px, 190px 77px, 20px 77px, 0 92px, 0 0);
        animation: none;
        position: static;
        // height: 12vh;
        clip-path: polygon(100% 0, 100% 8.3vh, 38vh 8.3vh, 32vh 10.8vh, 2vh 10.8vh, 0 12.3vh, 0 0);
        height: 12.3vh;

        // animation: mobile-top-outline-background 2s ease-in-out  1 both;

        @keyframes mobile-top-outline-background{
            0%{
                // clip-path: polygon(100% 0, 100% 362px, 230px 362px, 190px 377px, 20px 377px, 0 392px, 0 0);
                clip-path: polygon(100% 0, 100% 38.3vh, 48vh 38.3vh, 32vh 40.8vh, 2vh 40.8vh, 0 42.3vh, 0 0);
                height: 60vh;
            }
            100%{
                // clip-path: polygon(100% 0, 100% 62px, 230px 62px, 190px 77px, 20px 77px, 0 92px, 0 0);
                clip-path: polygon(100% 0, 100% 8.3vh, 38vh 8.3vh, 32vh 10.8vh, 2vh 10.8vh, 0 12.3vh, 0 0);
                // height: 95px;
                // height: 12.3vh;
            }
        }    
    }

    .background-foreground-top{
        // position: fixed;
        // width: 100vw;
        // height: 130px;
        left: 0;
        top: 0;
        // display: none;
        height: 15.7vh;
        background: url(../imgs/backgrounds/foreground2.jpg);
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;  
          
    
        // clip-path: polygon(0 0,100% 0, 100% 70px, 93% 70px, 90% 48px, 65% 48px, 62% 70px, 38% 70px, 35% 48px, 350px 48px, 300px 93px, 20px 93px, 0 118px);
        // clip-path: polygon(0 0,100% 0, 100% 59.5%, 93% 60.5%, 90% 41.1%, 65% 41.1%, 62% 59%, 38% 59%, 35% 40.5%, 350px 40.5%, 300px 77.5%, 20px 77.5%, 0 99.5%);

        // clip-path: polygon(0 0,100% 0, 100% 9.3vh, 93% 9.3vh, 90% 6.35vh, 65% 6.35vh, 62% 9.22vh, 38% 9.22vh, 35% 6.26vh, 350px 6.26vh, 300px 12.18vh, 20px 12.18vh, 0 15.7vh);

        // clip-path: polygon(0 0,100% 0, 100% 9.3vh, 93% 9.3vh, 90% 6.35vh, 65% 6.35vh, 62% 9.22vh, 38% 9.22vh, 35% 6.26vh, 50vh 6.26vh, 40vh 12.18vh, 4vh 12.18vh, 0 15.7vh);
        clip-path: polygon(0 0,100% 0,  100% 9.26vh, 90% 9.26vh,87% 6.26vh,50vh 6.26vh, 40vh 12.18vh, 4vh 12.18vh, 0 15.7vh);
        // animation: top-img-background 2s ease-in-out  1 both;
    
        @keyframes top-img-background{
            0%{
                clip-path: polygon(0 0,100% 0, 100% 480px, 93% 480px, 90% 450px, 65% 450px, 62% 480px, 38% 480px, 35% 450px, 450px 450px, 350px 515px, 20px 515px, 0 525px);
                height: 525px;
            }
            100%{
                clip-path: polygon(0 0,100% 0, 100% 70px, 93% 70px, 90% 48px, 65% 48px, 62% 70px, 38% 70px, 35% 48px, 350px 48px, 300px 93px, 20px 93px, 0 118px);
                height: 118px;
            }
        } 

        // @media (max-width: $breakpoint-tablet) {
        //     // clip-path: polygon(100% 0, 100% 62px, 230px 62px, 190px 77px, 20px 77px, 0 92px, 0 0);
        //     // clip-path: polygon(0 0,100% 0, 100% 9.3vh, 93% 9.3vh, 90% 6.35vh, 65% 6.35vh, 62% 9.22vh, 38% 9.22vh, 35% 6.26vh, 350px 6.26vh, 300px 12.18vh, 20px 12.18vh, 0 15.7vh);
        //     clip-path: polygon(0 0,100% 0, 100% 9.3vh, 40vh 9.3vh, 31.7vh 12.18vh, 5vh 12.18vh, 0 15.7vh);
        // }
    
    
        @media (max-width: $breakpoint-phone) {
            // display: none;
            // width: auto;
            display: block;
            background: url(../imgs/backgrounds/foreground2.jpg);
            background-position: top;
            background-repeat: no-repeat;
            background-size: cover;
            clip-path: polygon(100% 0, 100% 8vh, 38vh 8vh, 32vh 10.5vh, 2vh 10.5vh, 0 12vh, 0 0);
            animation: none;
            height: 95px;
            // top: -20px;
            // animation: mobile-top-img-background 2s ease-in-out  1 both;

            @keyframes mobile-top-img-background{
                0%{
                    // clip-path: polygon(100% 0, 100% 360px, 230px 360px, 190px 375px, 20px 375px, 0 390px, 0 0);
                    clip-path: polygon(100% 0, 100% 38.0vh, 48vh 38.0vh, 32vh 40.5vh, 2vh 40.5vh, 0 42.0vh, 0 0);
                    // height: 390px;
                    height: 60vh;
                }
                100%{
                    // clip-path: polygon(100% 0, 100% 60px, 230px 60px, 190px 75px, 20px 75px, 0 90px, 0 0);
                    clip-path: polygon(100% 0, 100% 8vh, 38vh 8vh, 32vh 10.5vh, 2vh 10.5vh, 0 12vh, 0 0);
                    // height: 20vh;
                    height: 12vh;
                }
            }               
        }
    }
}

.background-outline-bottom{
    opacity: 0.95;
    position: fixed;
    width: 100vw;
    // height: 10.8vh;
    height: 14vh;
    left: 0;
    bottom: 0;
    z-index: 200;
    // display: none;
    background-color: #28a5bf;
    // clip-path: polygon(0 0, 2% 50px, 20% 50px, 24% 25px, 55% 25px, 59% 50px, 97% 50px, 100% 20px, 100% 100%, 0 100%);

    // clip-path: polygon(0 0, 2% 6.6vh, 20% 6.6vh, 24% 3.3vh, 55% 3.3vh, 59% 6.6vh, 97% 6.6vh, 100% 2.6vh, 100% 100%, 0 100%);


    clip-path: polygon(0 100%,100% 100%, 100% 6.44vh,90% 6.44vh,87% 9.44vh,50vh 9.44vh, 40vh 3.52vh, 4vh 3.52vh, 0 0);
    transform: rotateY(180deg);

    // animation: bottom-outline-background 2s ease-in-out  1 both;

    @keyframes bottom-outline-background{
        0%{
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            height: 60vh;
        }
        1%{
            clip-path: polygon(0 0, 5% 60px, 20% 60px, 25% 20px, 55% 20px, 60% 60px, 97% 60px, 100% 20px, 100% 100%, 0 100%);
            height: 60vh;
        }
        100%{
            clip-path: polygon(0 0, 2% 50px, 20% 50px, 24% 25px, 55% 25px, 59% 50px, 97% 50px, 100% 20px, 100% 100%, 0 100%);
            height: 80px;
        }
    }

    // @media (max-width: $breakpoint-tablet) {
    //     height: 10vh;
    //     // clip-path: polygon(100% 0, 100% 62px, 230px 62px, 190px 77px, 20px 77px, 0 92px, 0 0);
    //     clip-path: polygon(100% 100%, 100% 4vh, 230px 4vh, 190px 2vh, 20px 2vh, 0 0, 0 100%);
    //     // transform: rotateY(180deg);
    // }    


    @media (max-width: $breakpoint-phone) {
        // display: none;
        // width: auto;
        // background-color: #28a5bf;
        height: 60px;
        clip-path: polygon(100% 100%, 100% 30px, 230px 28px, 190px 12px, 20px 12px, 0 0, 0 100%);
        transform: rotateY(180deg);
        // animation: mobile-bottom-outline-background 2s ease-in-out  1 both;
        position: static;

        @keyframes mobile-bottom-outline-background{
            0%{
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                height: 70vh;
            }
            1%{
                clip-path: polygon(100% 100%, 100% 30px, 230px 28px, 190px 12px, 20px 12px, 0 0, 0 100%);
                height: 70vh;
            }
            100%{
                clip-path: polygon(100% 100%, 100% 30px, 230px 28px, 190px 12px, 20px 12px, 0 0, 0 100%);
                height: 60px;
            }
        }        
        
    }

    .background-foreground-bottom{
        // position: fixed;
        // width: 100vw;
        // height: 80px;
        
        height: 14vh;
        // left: 0;
        // bottom: 0;
        background: url(../imgs/backgrounds/foreground2.jpg);
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        // display: none;
        // clip-path: polygon(0 2PX, 2% 52px, 20% 52px, 24% 27px, 55% 27px, 59% 52px, 97% 52px, 100% 22px, 100% 100%, 0 100%);

        // clip-path: polygon(0 0.3vh, 2% 6.9vh, 20% 6.9vh, 24% 3.6vh, 55% 3.6vh, 59% 6.9vh, 97% 6.9vh, 100% 2.9vh, 100% 100%, 0 100%);
        clip-path: polygon(0 100%,100% 100%,100% 6.74vh,90% 6.74vh,87% 9.74vh,50vh 9.74vh, 40vh 3.82vh, 4vh 3.82vh, 0 0.3vh);
        // animation: bottom-img-background 2s ease-in-out  1 both;

        @keyframes bottom-img-background{
            0%{
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                height: 60vh;
            }
            1%{
                clip-path: polygon(0 5px, 5% 65px, 20% 65px, 25% 25px, 55% 25px, 60% 65px, 97% 65px, 100% 25px, 100% 100%, 0 100%);
                height: 60vh;
            }
            100%{
                clip-path: polygon(0 2PX, 2% 52px, 20% 52px, 24% 27px, 55% 27px, 59% 52px, 97% 52px, 100% 22px, 100% 100%, 0 100%);
                height: 80px;
            }
        }   
        
        // @media (max-width: $breakpoint-tablet) {
        //     height: 10vh;
        //     // clip-path: polygon(100% 0, 100% 62px, 230px 62px, 190px 77px, 20px 77px, 0 92px, 0 0);
        //     clip-path: polygon(100% 100%, 100% 4.3vh, 230px 4.3vh, 190px 2.3vh, 20px 2.3vh, 0 0, 0 100%);
        //     // transform: rotateY(180deg);
        // }   
    
        @media (max-width: $breakpoint-phone) {
            // display: none;
            // width: auto;
            clip-path: polygon(100% 100%, 100% 32px, 230px 30px, 190px 15px, 20px 15px, 0 2px, 0 100%);
            // animation: none;
            height: 60px;
            // top: -20px;
            // animation: mobile-bottom-img-background 2s ease-in-out  1 both;

            @keyframes mobile-bottom-img-background{
                0%{
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                    height: 70vh;
                }
                1%{
                    clip-path: polygon(100% 100%, 100% 32px, 230px 30px, 190px 15px, 20px 15px, 0 2px, 0 100%);
                    height: 70vh;
                }
                100%{
                    clip-path: polygon(100% 100%, 100% 32px, 230px 30px, 190px 15px, 20px 15px, 0 2px, 0 100%);
                    height: 60px;
                }
            }                 
        }
    }

    ul{
        list-style: none;
        display: flex;
        position: absolute;
        // z-index: 2000;
        left: 0;
        // top: 45%;
        top: 45%;
        transform: rotateY(180deg);
        @media (max-width: $breakpoint-phone){
            transform: rotateY(180deg);
            left: 0;
            top: 2vh;
            // right: 0;
        }
        li{
            
            margin: 0 20px;
            @media (max-width: $breakpoint-phone){
                margin: 0 1.5vh;
            }
        }

    
        a{
            text-decoration: none;
            // height: 4.3vh;
            // width: 4.3vh;
            height: 5vh;
            width: 5vh;

            margin: 0;

            &:hover svg{
                color: white;
                animation: bounce 0.5s 1 ease;

                @keyframes bounce{
                    0%{
                        top: 0;
                    }
                    50%{
                        top: -10px;
                    }
                    // 50%{
                    //     top: 0;
                    // }
                    // 75%{
                    //     top: 20px;
                    // }
                    100%{
                        top: 0;
                    }
                }
            }

            // &:hover .insta{
            //     // background-color: rgb(236, 61, 149);
            // }
            // &:hover .fb{
            //     // background-color: rgb(46, 102, 224);
            // }
            // &:hover .linkedin{
            //     // background-color: rgb(16, 75, 151);
            // }

            svg{
                height: 6vh;
                width: 6vh;
                position: relative;
                // color:rgb(126, 126, 128);
                color: rgb(240, 238, 238);
                cursor: pointer;  
                padding: 5px;
                border-radius: 40%; 
                transition: color  0.2s, background-color  0.2s ease;

                @media (max-width: $breakpoint-phone){
                    height: 6vh;
                    width: 6vh;
                }

                
            }

            // .insta{
            //     color: rgb(77, 229, 240);

                
            // }
            
            // .fb{
            //     // color: rgb(46, 102, 224);
            //     // color: rgb(61, 224, 236);
            //     color: rgb(77, 229, 240);
            // }
            // .linkedin{
            //     // color: rgb(16, 75, 151);
            //     // color: rgb(61, 224, 236);
            //     color: rgb(77, 229, 240);
                
            // }
        }
    }
    
}
