// @import './breakpoints';

.landing-navigation{
    position: absolute;
    height: 100%;
    width: 100%;
    // left: 0;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%,-50%);

    display: flex;
    // justify-content: stretch;
    align-items: center;

    @media (max-width: $breakpoint-phone) {
        // width: 100%;
        // position: absolute;
        // position: relative;
        // height: 80%;
        position: static;
        // height: auto;
        // width: auto;
        // top: 60px;
        // padding: 90px 0;
        // left: 0;
        transform: none;
    }
    
    .landing-navigation-inner{
        
        display: flex;
        // flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        // padding: 0 20px;
        // width: 100%;
        // height: 75%;

        padding: 12vh 20px 10vh;
        width: 100%;
        height: 100vh;

        @media (max-width: $breakpoint-phone) {
            // padding: 0;
            
            // height: 100%;
            // width: 90%;
            // justify-content: flex-start;
            // margin-top: -10px;
            flex-direction: column;

            padding: 12vh 0 8vh;
            width: 100%;
            height: auto;
            padding: 0;
        }
    }
        .main{
            height: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;

            @media (max-width: $breakpoint-phone) {
                // align-items: stretch;
                width: 100%;
                // align-items: unset;
            }
        }

        .nav-icon{
            z-index: 2000;
        }

     
        .nav-logo-container{
            position: relative;
        }

        .nav{
            // opacity: 1;
            // display: none;
            h1{
                position: absolute;
                top: 50%;
                font-family: 'Orbitron', sans-serif;
                color: rgb(255, 255, 255);
                font-weight: 200;
                // font-size: 20px;
                font-size: 2.6vh;
                // letter-spacing: 2px;  
                letter-spacing: 3px;
                width: 100%;
                text-align: center;
                cursor: pointer;
    
                @media (max-width: $breakpoint-phone) {
                    // font-size: 20px;
    
                    font-size: 2.6vh;
                }
            }
        }

        // .nav-row{
            // width: 90%;
            // display: flex;
            // justify-content: space-between;
            // align-items: center;

            // @media (max-width: $breakpoint-phone) {
            //     width: 100%;
            //     flex-direction: column;
            // }


        #nav-logo{
                // height: 90px;
                height: 12.5vh;
                opacity: 0.3;
                // color: red;
                position: relative;
                // outline: red;
                // fill: red;
                

                @media (max-width: $breakpoint-phone) {
                    // height: 80px;

                    height: 11vh;
                    opacity: 0.3;
                }
        }
        .nav{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: transform 0.15s ease-out;

                @media (max-width: $breakpoint-phone) {
                    margin-top: 10px;
                    z-index: 100;
                    // width: 100%;
                }                

                &:hover{
                    transform: scale(1.1);
                }

                &:hover #nav-logo{
                    opacity: 0.48;
                }
        }
        .nav-icon{
                display: none;
                position: relative;
                top: -15px;
                // height: 70px;
                height: 9vh;
                transform-origin: center;
                transform-box: fill-box;                
                animation: icon-animate 5s ease-in-out infinite forwards;

                @media (max-width: $breakpoint-phone) {
                    // height: 60px;

                    height: 9vh;
                }

                @keyframes icon-animate{
                    0%{
                        transform: scale(1) translate(0, 0px);
                    }
                    50%{
                        transform: scale(1.05) translate(0, 10px);
                    }
                    100%{
                        transform: scale(1) translate(0,-0px);
                    }
                }
        }
         
        .left-nav{
              &:last-of-type{
                    align-self: flex-end;

                    // @media (max-width: $breakpoint-phone) {
                    //     align-self: unset;
                    // }
                }
                &:first-of-type{
                    align-self: flex-end;

                    @media (max-width: $breakpoint-phone) {
                        align-self: flex-start;
                    }
                }
                h1{
                    left: 50%;
                    transform: translate(-50%,-50%);
                }
                // .nav-icon{
                //     @media (max-width: $breakpoint-phone) {
                //         position: relative;
                //         left: 10px;
                //     }
                // }
                @media (max-width: $breakpoint-phone) {
                    // align-self: flex-start;
                    width: 250px;
                    // justify-content: flex-start;
                }
        }
        .left{
                
                
                @media (max-width: $breakpoint-phone) {
                    align-self: flex-start;
                    // width: 250px;
                    // justify-content: flex-end;
                }
                .nav-icon{
                    @media (max-width: $breakpoint-phone) {
                        position: relative;
                        left: 10px;
                    }
                }
        }
        .right-nav{
                &:first-of-type,&:last-of-type{
                    align-self: flex-start;
                    // @media (max-width: $breakpoint-phone) {
                    //     align-self: auto;
                    // }
                }
                #nav-logo{
                    transform: rotateY(180deg);
                }
                h1{
                    right: 50%;
                    transform: translate(50%,-50%);
                }
                // .nav-icon{
                //     @media (max-width: $breakpoint-phone) {
                //         position: relative;
                //         right: 10px;
                //     }
                // }
                @media (max-width: $breakpoint-phone) {
                    // align-self: flex-end;
                    width: 250px;
                    // justify-content: flex-end;
                }
         }
            
        .right{
                
                @media (max-width: $breakpoint-phone) {
                    align-self: flex-end;
                    // width: 250px;
                    // justify-content: flex-end;
                }
                .nav-icon{
                    @media (max-width: $breakpoint-phone) {
                        position: relative;
                        left: unset;
                        right: 10px;
                    }
                }
        }
        .pc{
                display: block;
                display: none;
                @media (max-width: $breakpoint-phone) {
                    display: none;
                }
        }
        .mobile{
                
                display: none;
                @media (max-width: $breakpoint-phone) {
                    display: block;
                    display: none;
                }
        }
        // }
        // .inner-row{
        //     width: 85%;
        //     @media (max-width: $breakpoint-phone) {
        //         width: 100%;
        //     }
        // }
    

   
}

.login-container{
    .login{
        float: left;
        margin: 10px;
        @media (max-width: $breakpoint-phone) {
            float: inherit;
        }
    }
    .signup{
        float: right;
        margin: 10px;
        @media (max-width: $breakpoint-phone) {
            float: inherit;
        }
    }
}