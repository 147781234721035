.landing-scene{
    position: absolute;

    
    @media (max-width: $breakpoint-phone){
        position: relative;
    }
    

    .landing-title{
        // border-left: 6px solid #212529;
        // padding-left: 10px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 73vh;
        align-items: center;
      
        // flex-wrap: wrap;

        @media (max-width: $breakpoint-phone){
            height: 80vh;
            justify-content: space-between;
            padding-top: 10%;
        }
    }

    .theme{
        h1{
            position: relative;
            // top: -200px;
            color: #3a2875;
            text-shadow: 0 0 4px white;
            font-size: 55px !important;
            font-family: 'Orbitron', sans-serif;
            letter-spacing: 10px;
            animation: theme 5s  infinite ease-in-out;

            @keyframes theme{
                0%{
                    letter-spacing: 10px;
                    // transform: translateY(-20px);
                    font-size: 55px !important;
                }
                50%{
                    letter-spacing: 15px;
                    // transform: translateY(0);
                    font-size: 60px !important;
                }
                100%{
                    letter-spacing: 10px;
                    // transform: translateY(-20px);
                    font-size: 55px !important;
                }
            }

            @media (max-width: $breakpoint-phone){
                font-size: 38px !important;
                // bottom: 12vh;

                animation: theme2 5s  infinite ease-in-out;

                @keyframes theme2{
                    0%{
                        letter-spacing: 4px;
                        // transform: translateY(-20px);
                        font-size: 38px !important;
                    }
                    50%{
                        letter-spacing: 10px;
                        // transform: translateY(0);
                        font-size: 60px !important;
                    }
                    100%{
                        letter-spacing: 4px;
                        // transform: translateY(-20px);
                        font-size: 38px !important;
                    }
                }
            }

            @media (max-width: $breakpoint-small-phone){
                font-size: 35px !important;
                // bottom: 12vh;

                animation: theme3 5s  infinite ease-in-out;

                @keyframes theme3{
                    0%{
                        letter-spacing: 2px;
                        // transform: translateY(-20px);
                        font-size: 35px !important;
                    }
                    50%{
                        letter-spacing: 5px;
                        // transform: translateY(0);
                        font-size: 38px !important;
                    }
                    100%{
                        letter-spacing: 2px;
                        // transform: translateY(-20px);
                        font-size: 35px !important;
                    }
                }
            }
            @media (max-width: 350px){
                animation: none;
                font-size: 35px !important;
                letter-spacing: 2px;
            }
        }

        
    }

    .caption{
        h2{
            color: #9bb1c9;
        font-family: 'Orbitron', sans-serif;
        letter-spacing: 4px;
        position: relative;
        font-size: 20px;
        font-weight: 400;
        // top: 200px;

        @media (max-width: $breakpoint-phone){
            color: rgb(238, 238, 238);
            // top: 18vh;
            font-size: 28px !important;
            text-align: center;
        }

        @media (max-width: $breakpoint-small-phone){
            font-size: 22px !important;
            // width: 100%;
        }

       
    }
}

    .title{
        // display: none;
        
        img{
            height: 200px;

            
            @media (max-width: $breakpoint-phone){
                height: auto;
                width: 80%;
            }
        }
    }

    .start{
        // display: none;
        position: relative;
        // left: -40%;
        // top: 2%;
        // padding: 0 50px;
        
       
        img{
            height: 100px;
            opacity: 0.50;
            cursor: pointer;

            @media (max-width: $breakpoint-phone){
                height: 110px;
            }

            @media (max-width: $breakpoint-small-phone){
               height: 85px;
                // width: 100%;
            }
        }

        &:hover{
            transform: scale(1.1);
        }
       
        &:after{
            content: 'Lets Explore !';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 350px;
            font-family: 'Bebas Neue', cursive;
            color: rgb(240, 238, 238);
            letter-spacing: 2px;
            
            font-weight: 200;
            font-size: 30px;     
            text-align: center;    
            // height: 100%;
            // width: 100%;
            font-style: italic;
            transform: translate(-50%,-50%);
            opacity: 1;
            cursor: pointer;

           

            @media (max-width: $breakpoint-phone) {
                font-size: 35px;

                // font-size: 2.7vh;
                // top: 3.3vh;
                // left: 11vh;
            }

            @media (max-width: $breakpoint-small-phone){
                font-size: 28px !important;
                // width: 100%;
            }
        }
    }


}



// .logo{
//     position: absolute;
//     right: 10%;
//     img{
//         height: 200px;
//     }
// }