@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&family=Roboto:wght@300;400;500;700&display=swap');

.signup-page{
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    // background-attachment: fixed;
    // background-size: cover;
    overflow: hidden;

    @media (max-width: $breakpoint-phone){
        // background: linear-gradient(0deg, #41287c, #703f90);
    }

    .signup{
        position: relative;
        z-index: 2000;
        overflow: hidden;
    }


    .cloud2{
        width: 300px;
        height: auto;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0.9;
        display: none;

        @media (max-width: $breakpoint-phone){
            width: 160px;
            display: none;
        }
    }
    
    .back{
        position: absolute;
        top: 20px;
        left: 20px;
        background-color: white;
        /* background-color: #41287c; */
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        color: #703f90;
        border: none;
        outline: none;
        cursor: pointer;
    }
    .back:hover{
        background-color: #9c2388;
        color: white;
    }
    
    .back i{
        position: relative;
        left: -1px;
    }

    .signup-inner{
        margin: 60px auto 50px;
        /* height: 600px; */
        width: 80%;
    //    display: flex;
       /* flex-wrap: wrap; */
       /* margin-top: 40px; */
       /* margin-bottom: 50px; */
       /* padding-bottom: 20px; */
    //    padding: 50px 0;
       /* box-shadow: 0 10px 20px  rgb(92, 60, 133); */
       border-radius: 5px;
       overflow: hidden;


       @media (max-width: $breakpoint-phone){
            width: 100%;
            /* margin: 20px 10px; */
            flex-direction: column-reverse;
            /* flex-wrap: wrap; */
            /* padding: 10px; */
            align-items: stretch;
            justify-content: center;
            margin: 0;
            padding: 80px 0 50px;
            height: auto;
            box-shadow: 0 10px 20px  rgb(92, 60, 133);
       }

    }

    .signup-detail{
        background-color: white;
        /* width: 35%; */
        // flex: 7;
        padding-bottom: 80px;
        /* border-top-left-radius: 20px; */
        /* height: 100%; */
        position: relative;

        @media (max-width: $breakpoint-phone){
            width: auto;
            /* margin-bottom: 80px; */
            margin: 0 10px 80px;
            flex: 1;
        }
    
        img{
            position: absolute;
            width: 25%;
            
            @media (max-width: $breakpoint-phone){
                width: 50%;
            }
        }

        .cloud3{
            width: 20%;
            height: auto;
            position: absolute;
            bottom: 0;
            right: 0;
            opacity: 0.9;
    
            @media (max-width: $breakpoint-phone){
                // width: 160px;
                // bottom: 0;
                width: 35%;
            }
          
        }

        .signup{
            font-family: 'Roboto', sans-serif;
            // margin-top: 30px;
            display: flex;
            flex-direction: column;
            /* flex-wrap: wrap; */
            /* align-items: center; */
            /* justify-content: start; */
            padding: 0 12%;

            @media (max-width: $breakpoint-phone){
                padding: 20px 15px 0;
            }
        }
        
        .signup-info{
            display: flex;
            flex-direction: column;
            /* flex-wrap: wrap; */
            color: #472b7f;
            margin-top: 60px;
            /* align-items: center; */
            @media (max-width: $breakpoint-phone){
                margin-top: 20px;
            }
           
        }

        h1{
            color: #472b7f;
            font-weight: 400;
            font-size: 40px;
            text-align: center;
            margin-top: 50px;

            @media (max-width: $breakpoint-phone){
                margin-top: 90px;
            }
        }

        h2{
            color: #472b7f;
            font-weight: 400;
            margin-bottom: 20px;
            text-align: center;
        }
        form{
            text-align: center;
        }

        .form-section{
            display: flex;
            flex-wrap: wrap;
            // justify-content: stretch;
            @media (max-width: $breakpoint-phone){
                flex-direction: column;
            }
        }

        .inner-section{
            flex: 1;
            padding: 0 20px;
            text-align: left;
            
            @media (max-width: $breakpoint-phone){
                padding: 0;
            }
        }

        .field{
            margin: 30px 10px;
        }

        .label{
            display: flex;
            align-items: center;

            
            svg{
                margin-right: 5px;
                // height: 20px;
                // width: 20px;
            }
        }
        label{
            font-size: 20px;
        }
        .remark{
            color: rgb(255, 0, 43);
            font-weight: 400;
            font-size: 16px;
            // line-height: 1.5;
        }

        input{
                outline: none;
                border: none;
                // color: #472b7f;
                color: #251447;
                font-size: 16px;
                background: none;
                width: 100%;
                margin-left: 5px;
                border-bottom: 1.5px solid #bdbcc2;
                font-weight: 400;
                font-size: 20px;
                padding-bottom: 5px;
                padding-top: 5px;
                /* font-weight: 400; */

            

                @media (max-width: $breakpoint-phone){
                    // justify-self: stretch;
                    width: 100%;
                    margin: 10px 0;
                }

                &::placeholder{
                    color: #5f5b68;
                    font-weight: 300;
                    font-size: 16px;
                }

                &:focus{
                    border-bottom: 1.9px solid #472b7f;
                    // transition: border-bottom 0.3s ease;
                }
        }
        

        button{
            /* margin-top: 40px; */
            background-color: transparent;
            background: linear-gradient(90deg, #704191, #4c2f81);
            border: none;
            outline: none;
            color: white;
            font-size: 20px;
            padding: 15px 0;
            border-radius: 7px;
            font-weight: 500;
            margin: 30px 0 10px;
            width: 200px;
            cursor: pointer;

            &:hover{
                background: linear-gradient(90deg, #9e1092, #8817b4);
            }
        }
       
      

        .login{
            display: flex;
            font-weight: 500;
            margin-top: 20px;
            justify-content: center;
            
            @media (max-width: $breakpoint-phone){
                text-align: center;
            }

            .newhere{
                color: #9b8abd;
                margin-right: 5px;
            }

            a{
                text-decoration: none;
            }
        }
    }


    .signup-img{
        // background: linear-gradient(90deg, #5d3689, #41287c);
        // background-color: rgba(0,0,0,0.2);
        /* width: 65%; */
        position: absolute;
        top: -30px;
        right: -30px;
        height: 100%;
        width: 100%;
        // flex: 1;
        /* height: 100%; */
        /* align-self: stretch;
        display: flex; */

        display: flex;
        justify-content: flex-end;
        align-items: flex-start;;

        img{
            opacity: 0.5;
            height: 50%;
            width: auto;

            @media (max-width: $breakpoint-phone){
                width: 100%;
                opacity: 0.2;
            }
        }

        @media (max-width: $breakpoint-phone){
            // flex: 1;
            // background: linear-gradient(90deg, #5d3689, #41287c);
            // background: none;
            // display: none;
            right: -80px;
        }
        
        // .signup-logo{
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: space-evenly;
        //     /* flex-wrap: wrap; */
        //     align-items: center;
        //     height: 100%;

        //     @media (max-width: $breakpoint-phone){
        //         padding: 50px 0 40px;
        //         /* margin-top: 80px; */
        //         margin: 30px 10px 20px;}
        // }
     
        // img{
        //     width: 50%;   
        //     /* animation: planets 10s ease infinite ; */
        //     @media (max-width: $breakpoint-phone){
        //         width: 80%;
        //         /* height: auto; */
        //         margin: 20px 0;
        //     }
        // }

        // h1{
        //     color: white;
        //     font-family: 'Special Elite', cursive;
        //     line-height: 1.5;
        //     font-size: 45px;
        //     text-transform: uppercase;
        //     letter-spacing: 2px;

        //     @media (max-width: $breakpoint-phone){
        //         font-size: 35px;
           
        //         letter-spacing: 2px;
        //     }
        // }

        // h2{
        //     color: white;
        //     font-family: 'Special Elite', cursive;
        //     letter-spacing: 2px;
        //     line-height: 1.5;
        //     text-align: center;

        //     @media (max-width: $breakpoint-phone){
        //         font-size: 22px;
        //         letter-spacing: 2px;
        //         line-height: 1.5;
        //     }
        // }
    }    


}