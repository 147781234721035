@import './breakpoints';

@import './fonts';

// @import './background_stars';
@import './background_stars';

@import './background_foreground';
@import './background_circle';
@import './planets';
@import './landing_header';

#bg-3d{
  position: fixed;
  top: 0;
  left: 0;
  height:100%;
  width: 100%;
}
.back{
  position: absolute;
  top: 40px;
  left: 20px;
  background-color: white;
  /* background-color: #41287c; */
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  color: #703f90;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 20000;

  @media (max-width: $breakpoint-phone){
    top: 20px;
    // position: fixed;
  }
}
.back:hover{
  background-color: #9c2388;
  color: white;
}

.back i{
  position: relative;
  left: -1px;
}
@import './event_menu';
@import './event_page';
@import './team_page';
@import './spons_page';
@import './contact_us';
@import './login_page';
@import './signup_page';
@import './gallery1';
@import './about';
@import './landing_scene';
@import './landing_front';
@import './toggle_nav';

#root{
  z-index: 10;
  position: relative;
}
#star-container{
  z-index: 9;
}

::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #090a0f; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ed1865; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #452F90; 
}
// body{
//     // height: 100%;
//     // width: 100%;
//     // overflow: hidden;
    
// }
// #root{
//     height: 100%;
//     width: 100%;
//     overflow: hidden;
// }


.landing-background{
    // position: relative;
    // position: fixed;
    // left: 0;
    // top: 0;
    // height: 100vh;
    // width: 100vw;
    // height: 100vh;
    // overflow: hidden;
    // display: flex;
    // flex-direction: column;
    position: relative;

    //background: url(../imgs/backgrounds/36.jpg);
    // following code till the height of site is insufficient
    height: 100vh;
    @media (max-width: $breakpoint-phone) {
        height: auto;
        overflow: hidden;
        background: url(../imgs/backgrounds/10.jpg);
        // text-align: center;
        // height: auto;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
    }
    // end
   
    // background-position: left;
    // background: url(../imgs/backgrounds/background.jpg);
    
    // background-position: left;
}
.default-background-image{


}

.blackTransparent{
  // background-color: rgba(94,32,105,0.05);
  background-color: rgba(0,0,0,0.2);
  height: 100%;
  width:100%;
  position: absolute;
  left: 0;
  right: 0;

  @media (max-width: $breakpoint-phone) {
    display: none;
    // opacity: 0.1;
  }
}


// // Scroll Bar 
// ::-webkit-scrollbar {
//   width: 15px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background: #888; 
// }
 
// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #555; 
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: black; 
// }

// h1#theme{
//   font-size: 55px !important;
// }

.comet{
  height: 90px;
  width: 90px;
  position: absolute;
  top: 0;
  right: 0;
  transform: rotateZ(-10deg);
  animation: comet1 10s infinite linear;

  @media (max-width: $breakpoint-phone){
    animation: comet2 10s infinite linear;
    transform: rotateZ(-20deg);
  }

  @keyframes comet1 {
    0%{
      right: 50%;
      top: 0;
      opacity: 0;
    }
    100%{
      right: 110%;
      top: 80vh;
      opacity: 0.8;
    }
  }
  @keyframes comet2 {
    0%{
      right: 10%;
      top: 0;
      opacity: 0;
    }
    100%{
      right: 110%;
      top: 80vh;
      opacity: 0.8;
    }
  }
}

.ship{
  height: 90px;
  width: 90px;
  position: absolute;
  top: 0;
  right: 0;
  // transform: rotateZ(-10deg);
  animation: ship 10s 5s infinite ease-in;
  opacity: 0.9;

  @keyframes ship {
    0%{
      opacity: 0;
      right: 60%;
      top: 0;
    }
    // 5%{
    //   opacity: 0.9;
    //   right: 60%;
    //   top: 0;
    // }
    // 90%{
    //   right: 0%;
    //   top: 80vh;
    //   opacity: 0.9;
    // }
    // 91%{
    //   opacity: 0;
    // }
    // 99%{
    //   right: 0%;
    //   top: 80vh;
    //   opacity: 0;
    // }
    100%{
      right: 0%;
      top: 80vh;
      opacity: 0.9;
    }
  }
}
