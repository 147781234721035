// @import './breakpoints';
.sponsor-page{
    // background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    // background-attachment: fixed;
    background-size: contain;
    background-repeat: repeat-y;
    background-position: center;
    // background-repeat: repeat-y;
    // overflow: hidden;
    // padding: 100px 0;
    // padding-top: 30px;

    position: relative;

    background: url(../imgs/backgrounds/37.jpg);

    // width: 100vw;
    @media (max-width: $breakpoint-phone) {
        height: auto;
        overflow: hidden;
        background: url(../imgs/backgrounds/10.jpg);
        padding-top: 45px;
    }
    

    .back-section{
        a{
            text-decoration: none;
        }

    }

    // .back{
    //     color: white;
    //     background-color: #452F90;
    //     // background-color: #ed1865;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     // height: 50px;
    //     height: 80px;
    //     width: 80px;
    //     border-radius: 50%;
    //     // clip-path: polygon(0 0, 100% 0, 100% 70%, 90% 100%, 0 100%);
    //     margin: 0 20px;
    //     cursor: pointer;

    //     @media (max-width: $breakpoint-phone) {
    //         margin: auto;
    //     }

    //     &:hover{
    //         background-image: linear-gradient(-45deg, #452F90 0%, #c32170 100%);
    //     }
    // }

    .spons-years{
        max-width: 900px;
        margin: 30px auto auto;
        display: flex;
        // justify-content: space-between;
        // justify-content: stretch;
        position: relative;
        top: 20px;
        z-index: 201;
        @media (max-width: $breakpoint-phone) {
            // width: 100%;
            // margin: auto 20px;
            // margin: auto 20px;
            padding: 0 20px;
        }

    }

    .year{
        flex: 1;
        color: white;
        background-color: #452F90;
        // background-color: #ed1865;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        // border-right: 2px solid white;
        // width: 100px;
        // clip-path: polygon(0 0, 100% 0, 100% 70%, 90% 100%, 0 100%);
        // margin: 0 20px;
        cursor: pointer;

        &:hover{
            // background-color: #ed1865;
            background-image: linear-gradient(-45deg, #452F90 0%, #c32170 100%);
        }

        &:first-of-type{
            border-right: 1px solid white;
        }
    }
}
.sponsor{
    text-align: center;
    font-family: 'Bebas Neue', cursive;
     padding: 0 20px 100px;
    //  margin-top: 100px;
    
    // color:#130722;
    position: relative;
    z-index: 200;

    .sponsor-inner{
        // width: 60%;
        // width: 60vw;
        max-width: 900px;
        margin: auto;
        padding-top: 50px;
        padding-bottom: 10px;
        // background-color: rgba(0, 0, 0, 0.5);
        background-color: rgba(255,255,255,1);
    }
    h1{
        font-size: 60px;
        letter-spacing: 4px;
        line-height: 1.5;
        font-style: italic;
        color: #452F90;
        // color: #ed1865;

        @media (max-width: $breakpoint-phone) {
            font-size: 40px;
        }
    }
    h3{
        // color: white;
        // text-transform: lowercase;
        text-transform: uppercase;
        // font-size: 30px;
        font-size: 25px;
        // letter-spacing: 2px;
        line-height: 1.5;
        font-weight: 300;
        font-family: sans-serif;
        // color: #452F90;
        color: #ed1865;

        @media (max-width: $breakpoint-phone) {
            font-size: 20px;
        }
    }
    .spons-section{
        margin: 80px 0;

        // &:last-of-type{
        //     margin-bottom: 50px;
        // }
        // padding: 0 50px;
    }
    .spons-imgs{
        // display: grid;
        // grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
        // grid-gap:20px;
        margin-top: 20px;
        // padding: 0 20px;
        // grid-template-rows: repeat(auto-fit,100px);

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .spons-img{
        display: flex;
        justify-content: center;
        align-items: center;
        // height: 100px;
        margin: 20px;
    }
    img{
        // width: 100%;
        // height: 100%;
        // width: 200px;
        // max-height: 100%;
        // max-width: 100%;
        // width: 200px;
        // height: 100px;
        width: 200px;
        @media (max-width: $breakpoint-phone) {
            width: 180px;
        }
    }
}
