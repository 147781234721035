@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Patrick+Hand&display=swap');
.toggle-nav{

    padding-top: 15px;
    // margin-bottom: 30px;
    
    @media (max-width: $breakpoint-phone){
        padding: 0;
        position: fixed;
        right: 0;
        top: 80px;
        // background-color: red;
        width: 100%;
        z-index: 3000;
    }

    .inner-toggle{
        width: 90%;
        margin: auto;
        
        @media (max-width: $breakpoint-phone){
            width: 95%;
        }
    }

    .toggler-menu{
        display: none;

        @media (max-width: $breakpoint-phone){
        display: flex;
            flex-direction: column;
            background-color: rgba(93, 73, 158, 0.95);
            border-radius: 10px;
            transition: all 0.5 ease;
            // box-shadow: 0 0 5px rgba(93, 73, 158, 0.2);
        }
    }
    .toggler-menu-2{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @media (max-width: $breakpoint-phone){
            display: none;
        }
    }

    .toggler{
        // background-color: rgba(69,47,144,0.65);
        margin: 0 5px;
        // clip-path: polygon(0 0, 100% 0, 100% 70%, 90% 100%, 10% 100%, 0 70%);
        border-radius: 5px;
        border-bottom: 2px solid rgba(93, 73, 158, 0.65);
        box-shadow: 0 0 5px rgba(93, 73, 158, 0.2);
        
        &:hover{
            background-color: rgba(69,47,144,0.6);
        }

        
        @media (max-width: $breakpoint-phone){
            // display: none;
            text-align: center;
            padding: 7px 0;
            margin: 0;
            background-color: transparent;
            box-shadow: 0 0 1px rgba(255,255,255,0.7);
            

            &:last-of-type{
                border-bottom: none;
                padding-bottom: 12px;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            &:first-of-type{
                padding-top: 12px;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
            }
            &:hover{
                background-color: rgba(141, 73, 158, 0.85);
            }
        }
        
    }

    .toggler-nav{
        text-decoration: none;
        color: white;
        font-size: 20px;
        text-transform: capitalize;
        padding: 15px 15px 10px !important;
        font-family: 'Roboto', sans-serif;
        line-height: 1.4;
        font-weight: 300;
        letter-spacing: 1px;

        @media (max-width: $breakpoint-phone){
            
        }
    }

    .menu-icon{
        display: none;

        @media (max-width: $breakpoint-phone){
            position: fixed;
            top: 20px;
            right: 10px;
            display: block;
            // background-color: white;
            // height: 20px;
            // width: 20px;
            color: white;
            border: none;
           
            background-color: rgba(93, 73, 158, 0.85);
            border-radius: 10px;

            svg{
                height: auto;
                width: 40px;
            }
        }
    }
}