@import "breakpoints";

.merch-img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 400px;
    width: 400px;
    @media (max-width: $breakpoint-phone) {
            width: 150px;
            height: auto;
    }
}

.logo-img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
    width: 100px;
    @media (max-width: $breakpoint-phone) {
            width: 75px;
            height: auto;
    }
}

.merch-title{
    position: relative!important;
    margin-left: 10px;
    @media (max-width: $breakpoint-phone) {
        margin-top: 33px!important;
        margin-left: 0px;
    }
}
.merch-heading{
    position: relative!important;
}