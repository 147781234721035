@import './breakpoints';

.dashboard-img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  @media (max-width: $breakpoint-phone) {
    width: 150px;
    height: auto;
}
}
.event-button {
    // width: 100%;
    /* position:absolute;
   top:450px;
   left:680px; */
    //   background-image: linear-gradient(-45deg,transparent 20px, #452F90 20px, #452F90 50);
    background-color: #452F90;
    border: none;
    color: white;
    //   padding: 15px 32px;
    padding: 15px 80px;
    text-align: center;
    text-decoration: none;
    /* display: inline-block; */
    font-size: 30px;
    font-style: italic;
    letter-spacing: 2px;
    /* margin: 4px 2px; */
    cursor: pointer;
    transition: transform 0.25s ease-out;
    font-family: 'Bebas Neue', cursive;
    clip-path: polygon(0 0, 100% 0, 100% 65%, 90% 100%, 0 100%);
    transition: transform 0.25s ease;
    @media (max-width: $breakpoint-phone) {
      font-size: 25px;
      padding: 10px 60px;
    }

    &:hover {
      background-image: linear-gradient(-45deg, #452F90 0%, #c32170 100%);
      transform: scale(1.1);
    }
}