@import url("http://fonts.cdnfonts.com/css/gill-sans-mt-condensed");



::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: rgb(236, 97, 5);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.team_container {
  display: flex;
  align-items: stretch;
  align-content: stretch;
  justify-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  //padding-top: 20px;
  position: relative;
}


/* .team_container::before {
  position: absolute;
  content: "";
  background-color: #fae713;
  height: 200px;
  width: 200px;
  top: 0;
  left: 0;
  border-radius: 50%;
  z-index: -1;
} */

/* .team_container::after {
  position: absolute;
  content: "";
  background-color: #ff00ba; 
  height: 200px;
  width: 200px;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  z-index: -1;
} */
.team_card {
  margin:35px 20px;
  position: relative;
  display: inline-block;
  border-radius: 10px;
  /*height: 600px;*/
  /*width: 300px;*/
  width:280px;
  background-image: linear-gradient(135deg, rgba(52, 35, 108, 0.9) 0%, rgba(106, 23, 63, 0.95) 100%);
  //background-image: linear-gradient(135deg, rgba(69, 47, 144, 0.9) 0%, rgba(195, 33, 112, 0.95) 100%);
  //background-image: linear-gradient(135deg, #1c215c 0%, #04010f 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform-style: preserve-3d;
  transform: perspective(1000px);
}
.team_card::after {
  position: absolute;
  /* content: "Nike"; */
  height: max-content;
  width: 300px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  font-size: 100px;
  font-weight: 900;
  letter-spacing: 4px;
  color: rgba(255, 255, 255, 0.267);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.team_card .team_image {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  /*margin-top: 30px;*/

  /*width: 100%;*/
  /*height: 35%;*/
}
.team_card .team_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 90%;
  height: 65%;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0 0 50px 0 rgba(51 ,51, 51, 0.3);
  transform: translateZ(30px);
  transition: 0.5s;

  p{

  }
}

.team_card .team_image img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  transition: 0.5s;
}

.team_card:hover .team_info {
  transform: translateZ(60px);
  transition: 0.5s;
}
.team_card:hover .team_image img {
  transform: translateZ(60px);
  transition: 0.5s;
}

/* .team_card:hover .team_team_info p {
  font-size: 12px;
} */
.color-choose {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}
.color-choose span {
  cursor: pointer;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.color-choose span.selected::after {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  background-color: transparent;
  border: 1px solid red;
  z-index: -1;
  border-radius: 50%;
}


.price {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  margin: 10px 0;
}
.price input {
  outline: 0;
  border: 0;
  padding: 10px 20px;
  border-radius: 20px;
  background-image: linear-gradient(135deg, #ff00ba 0%, #fae713 100%);
  font-size: large;
  cursor: pointer;
  color: white;
}
.price sup {
  font-size: 11px;
  animation: 0.5s color linear infinite;
}
.size select {
  outline: 0;
  border: 0;
  padding: 2px 20px;
  border-radius: 4px;
  background: #ff00ba;
  background-image: linear-gradient(135deg, #ff00ba 0%, #fae713 100%);
  color: white;
}

.size select option {
  margin: 0 10px;
  border: 1px solid red;
}
.fa {
  padding: 10px;
  font-size: 22px;
  width: 40px;
  border-radius: 10px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
}

.fa:hover {
    opacity: 0.7;
}

.fa-facebook {
  background: #3B5998;
  color: white;
}
.fa-linkedin {
  background: #007bb5;
  color: white;
}

@keyframes color {
  from {
    color: rgb(20, 172, 0);
  }
  to {
    color: black;
  }
}

#personName{
  font-size: 24px;
  color: #18161A;
  font-family: "Bebas Neue", cursive;
  font-style: italic;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-weight: 400;
}
.team-page .team-page-inner {
  color: #01388a;;
  text-transform: capitalize;
}
.socialIcon{
  margin-right: 20px;
  width: 50px;
}


.a2
{
  display: none;

}
form{
  display: inline-block;
}
.button
{
  
  background-color: transparent;
  color: white;

}

:root {
  --color-red: #ec1840;
  --color-purple: #7a18ec;
  --color-white: #fff;
  --color-black-1: #111;
  --color-black-2: #222;
  --color-black-3: #444;
  --speed-normal: 0.4s;
  --speed-fast: 0.7s;
}

.button {
  position: relative;
  width: 180px;
  height: 60px;
  margin: 20px;
  margin-left: 30px;
  line-height: 60px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  border-radius: 51px;

  
}



.active
{
  transition: all 0.5s;
  border-radius: 51px;
  background: #7a18ec;
  box-shadow:  -10px -10px 39px #480e8b,
             10px 10px 39px #ac22ff;

    
}