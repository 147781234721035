// @import './breakpoints';


.team-page{
//     text-align: center;

    
//     background-color: #193c6d;
//     // filter: progid: DXImageTransform.Microsoft.gradient(gradientType=1, startColorstr='#003073', endColorstr='#029797');
//     background-image: url(//img.alicdn.com/tps/TB1d.u8MXXXXXXuXFXXXXXXXXXX-1900-790.jpg);
//     /*background-size: 100%;*/
//     background-image: -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0, #003073), color-stop(100%, #029797));
//     background-image: -webkit-linear-gradient(135deg, #003073, #029797);
//     background-image: -moz-linear-gradient(45deg, #003073, #029797);
//     background-image: -ms-linear-gradient(45deg, #003073 0, #029797 100%);
//     background-image: -o-linear-gradient(45deg, #003073, #029797);
//     background-image: linear-gradient(135deg, #003073, #029797);
//     text-align: center;
//     // margin:0px;



    
//     background-attachment: fixed;
//   background-size: cover;
//   background-repeat: repeat-y;
//   padding: 70px 0;

    //background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    // background-attachment: fixed;
    // background-size: cover;
    // background-repeat: repeat-y;
    // height: 100%;
    overflow: hidden;
    padding-bottom: 100px;
    // padding-top: 70px;
    font-family: sans-serif;
    text-align: center;
    //background: url(../imgs/backgrounds/37.jpg);

  .team-page-inner{
    background-color: rgba(0, 0, 0, 0.5);
    color: rgb(238, 238, 238);
    // font-weight: bold;
    // border: 3px solid #f1f1f1;
    position: relative;
    // top: 230%;
    // left: 50%;
    // transform: translate(-50%, -80%);
    z-index: 2;
    max-width: 70%;
    padding: 30px 20px 100px;
    
    margin: 70px auto auto;

    @media (max-width: $breakpoint-phone) {
        padding: 20px 0;
        max-width: 95%;
    }

    p{
        color: black;
    }
  }

  h1{
    font-size:70px;
    color:#E1DBE9;
    // text-shadow: 2px 2px darkblue;
    // font-family:'source-sans-pro',sans-serif;
    font-family: 'Bebas Neue', cursive;
    font-style: italic; 
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 4px;
    // font-weight: normal;
    font-weight: 400;
    @media (max-width: $breakpoint-phone) {
        font-size: 50px;
    }
  }
}
// .team-inner{
//     // padding: 0 100px;
// }
.team-menu{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    // margin: auto;
    .member-container{
        margin: 50px 20px;
        display: flex;
        flex-direction: column;

    }
    h3,h2{
        text-transform: uppercase;
        font-family: 'Bebas Neue', cursive;
    }
    h2{
        font-size:35px;
        position: relative;
        // top: -20px;
        // right: -20px;
        color:#E1DBE9 ;
        // color: #ed1865;
        // text-shadow: 1px 1px #ACE5EE;
        font-style: italic;
        text-align: center;
        font-family: 'Bebas Neue', cursive;
        letter-spacing: 2px;
        width: fit-content;
        margin: 0 auto;
        padding: 5px 30px;
        clip-path: polygon(0 0, 100% 0, 100% 70%, 90% 100%, 0 100%);
        background-color: #452F90;
        font-weight: 200;
        @media (max-width: $breakpoint-phone) {
            font-size: 30px;
            // top: -30px;
        }
    }
    h3{
        font-weight: 500;
        letter-spacing: 1px;
        font-size: 30px;
        margin-bottom: 20px;
        @media (max-width: $breakpoint-phone){
            font-family: 'Roboto', sans-serif;
            font-size: 20px;
            text-transform: capitalize;
            font-weight: 400;
        }
    }
    .member-details{
        position: relative;
        color: white;
        overflow: hidden;
        margin-top: 40px;

        @media (max-width: $breakpoint-phone){
            overflow: visible;
            position: static;
        }

        &:hover .content-overlay{
            opacity: 1;
        }
        &:hover .member-info{
            top: 50%;
            // left: 50%;
            opacity: 1;
        }
    }
    
    .content-overlay{
        background: rgba(0,0,0,0.7);
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        // bottom: 0;
        // right: 0;
        opacity: 0;
        transition: all 0.4s ease-in-out 0s;
        
        @media (max-width: $breakpoint-phone) {
            display: none;
        }
    }
    .member-image{
        width: 400px;
        height: 300px;
        object-fit: cover;
        // border:3px solid #ff1493;
        // border:3px solid #ff1493;
        border-radius: 20px;
        // box-shadow: 0 10px 20px 20px rgba(128, 128, 128, 0.5);
        position: relative;
        z-index: -1;


        @media (max-width: $breakpoint-phone) {
            width: 100%;
            height: 250px;
        }
    }
    .member-info{
        position: absolute;
        // text-align: center;
        // padding-left: 1em;
        // padding-right: 1em;
        display: flex;
        // flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        top: 20%;
        left: 50%;
        opacity: 0;
        padding: 0 20px;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease-in-out 0s;

        @media (max-width: $breakpoint-phone) {
            position: static;
            opacity: 1;
            transform: none;
            margin-top: 20px;
            // margin: 0;
            top: 0;
            left: 0;
            height: auto;

        }
    }
    p{
        font-size: 20px;
        font-family: sans-serif;
        font-weight: 300;
        color: #ff1493;
    }
    // .fadeIn-top{
    //     top: 20%;
    // }
    .links{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        a{
            text-decoration: none;
            color: white;
            font-size: 20px;
            // margin: 20px;
            // width: 50px;
            padding: 10px 20px;
            margin: 0 20px;
            @media (max-width: $breakpoint-phone) {
            //    padding: 10px 20px;
            padding: 0;
                height: 50px;
                width: 50px;
               border-radius: 50%;
               display: flex;
               align-items: center;
               justify-content: center;
               margin: 0 15px;
            }
            // padding: 20px;
        }
        .facebook{
            background: #3B5998;
            &:hover{
                opacity: 0.7;
            }
        }
        .linkedin{
            background: #007bb5;
            &:hover{
                opacity: 0.7;
            }
        }
    }
    
}

// .team-page-heading{
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     position: fixed;
//     // z-index: -2;
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%,-50%);

//     @media (max-width: $breakpoint-phone) {
//       flex-direction: column;
//       margin-bottom: 20px;
//     }
//     .space{
        
        
//         position: relative;
        
//         .planet {
//           width:400px;
//           height:400px;
//           border-radius:400px;
//           background-size: 374px;
//           background-color:rgb(248, 157, 218);
//           // margin-left:156px;
//           // margin-top:70px;
//           margin-right: 10px;
//           transform: rotate(20deg);
//           -webkit-transform: rotate(20deg);
//           animation: rotate 25s infinite linear;
//           -webkit-animation: rotate 25s infinite linear;
//           -moz-animation: rotate 25s infinite linear;
//           -o-animation: rotate 25s infinite linear;
//           padding-top:4px;
//           background: url(../imgs/backgrounds/planet2.jpg);
//           box-shadow: inset 8px 8px 10px rgba(253, 232, 247, 0.479),
//           inset -19px -19px 70px rgba(0,0,0,0.9),
//           inset -5px -5px 8px rgba(0,0,0,0.7),
//           -5px -5px 10px 1px rgba(138, 138, 138, 0.5),
//           5px 5px 30px 1px rgba(66, 14, 87, 0.5);
//           opacity: 1;
          
//         //   @media (max-width: $breakpoint-phone) {
//         //     border-radius:50vw;
//         //     height: 50vw;
//         //     width: 50vw;
//         //   }
//         }
       
//     }
// }
