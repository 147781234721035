@import '../../scss/breakpoints';

.coming-soon-page{
  height:100vh;
  width:100vw;
  display:flex;
  justify-content: center;
  align-items: center;
  overflow:hidden;
  background: url(../../imgs/backgrounds/36.jpg);

  @media (max-width: $breakpoint-phone) {
    background: url(../../imgs/backgrounds/10.jpg);
    flex-direction: column;
    justify-content: space-between;
  }

  .inner{
    position:relative;
    z-index: 2;
  }

  .message,.title{
    cursor: default;
    color:#E1DBE9;
    font-family: 'Bebas Neue', cursive;
    font-style: italic;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-weight: 400;

    @media (max-width: $breakpoint-phone) {
      letter-spacing: 8px;
    }
  }

  .message{
    font-size:70px;
    color: #ed1865;
    transition: all 2.5s ease-in-out;

    &:hover{
      transform: scale(1.1);
    }

    @media (max-width: $breakpoint-phone) {
      font-size: 50px;
    }
  }
  .title{
    font-size:60px;

    @media (max-width: $breakpoint-phone) {
      font-size: 40px;
    }
  }

  a{
    text-decoration:none;
  }

  .back-coming{
    margin: auto;
    background-color: white;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: #703f90;
    border: none;
    outline: none;
    cursor: pointer;
    z-index: 20000;
  }

  .back-coming:hover{
    background-color: #9c2388;
    color: white;
  }

  .back-coming i{
    position: relative;
    left: -1px;
  }

  .cta {
    position: relative;

    img{
      height: 100px;
      opacity: 0.50;
      cursor: pointer;

      @media (max-width: $breakpoint-phone) {
        height: 110px;
      }

      @media (max-width: $breakpoint-small-phone) {
        height: 85px;
      }
    }

    &:hover {
      transform: scale(1.1);
    }

    &:after {
      content: 'Back';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      font-family: 'Bebas Neue', cursive;
      color: rgb(240, 238, 238);
      letter-spacing: 2px;

      font-weight: 200;
      font-size: 30px;
      text-align: center;
      font-style: italic;
      opacity: 1;
      cursor: pointer;


      @media (max-width: $breakpoint-phone) {
        font-size: 35px;
      }

      @media (max-width: $breakpoint-small-phone) {
        font-size: 28px !important;
      }
    }
  }
}
