@import './breakpoints';

.event-page {
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  // background-attachment: fixed;
  // background-size: cover;
  // background-repeat: repeat-y;
  // height: 100%;
  overflow: hidden;
  padding-bottom: 100px;
  // padding-top: 70px;
  font-family: sans-serif;

  .mine {
    background: url(../imgs/backgrounds/planet2_1.jpg);
    box-shadow: inset 8px 8px 10px rgba(253, 232, 247, 0.479),
    inset -19px -19px 70px rgba(0, 0, 0, 0.9),
    inset -5px -5px 8px rgba(0, 0, 0, 0.7),
    -5px -5px 10px 1px rgba(138, 138, 138, 0.5),
    5px 5px 30px 1px rgba(66, 14, 87, 0.5);
    opacity: 0.9;
    @media (max-width: $breakpoint-phone) {
      background: url(../imgs/backgrounds/planet2.jpg);
    }
  }

  .page-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media (max-width: $breakpoint-phone) {
      flex-direction: column;
      margin-bottom: 40px;
      margin-top: 40px;
    }
  }

  .event-page-inner {
    // background-color: rgb(0,0,0); /* Fallback color */

    // background-color: rgba(237, 24, 101, 0.5); /* Black w/opacity/see-through */
    background-color: rgba(0, 0, 0, 0.5);
    color: rgb(238, 238, 238);
    // font-weight: bold;
    // border: 3px solid #f1f1f1;
    position: relative;
    // top: 230%;
    // left: 50%;
    // transform: translate(-50%, -80%);
    z-index: 2;
    max-width: 60%;
    padding: 30px 20px 100px;
    // text-align: center;
    margin: 70px auto auto;

    @media (max-width: $breakpoint-phone) {
      padding: 20px 0;
      max-width: 90%;
    }
  }

  h1 {
    font-size: 70px;
    color: #E1DBE9;
    // text-shadow: 2px 2px darkblue;
    // font-family:'source-sans-pro',sans-serif;
    font-family: 'Bebas Neue', cursive;
    font-style: italic;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 4px;
    // font-weight: normal;
    font-weight: 400;
    position: relative;
    top: 8px;
    // line-height: 0;

    @media (max-width: $breakpoint-phone) {
      font-size: 50px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      letter-spacing: 8px;
    }
  }

  .event-info {
    margin: 50px 20px 0;
    font-size: 18px;
    line-height: 1.5;
    // overflow: hidden;
    text-align: center;
    // padding: 20px;
    // border: 6px solid #452F90;
    // border-right: 6px solid #452F90;
    // border-radius: 40px;
    // background-color: rgba(255,255,255,0.5);

    @media (max-width: $breakpoint-phone) {
      margin: 0 0 50px;
    }


    &:first-of-type {
      margin-top: 70px;

      @media (max-width: $breakpoint-phone) {
        margin-top: 50px;
      }
    }
  }

  h2 {
    font-size: 35px;
    position: relative;
    // top: -20px;
    // right: -20px;
    color: #E1DBE9;
    // color: #ed1865;
    // text-shadow: 1px 1px #ACE5EE;
    font-style: italic;
    text-align: center;
    font-family: 'Bebas Neue', cursive;
    letter-spacing: 2px;
    width: fit-content;
    margin: 0 auto;
    padding: 5px 30px;
    clip-path: polygon(0 0, 100% 0, 100% 70%, 90% 100%, 0 100%);
    background-color: #452F90;
    font-weight: 200;
    @media (max-width: $breakpoint-phone) {
      font-size: 30px;
      // top: -30px;
    }
  }

  p {
    // text-align: left;
    text-indent: 25px;
    margin-top: 20px;
    font-family: 'Roboto', sans-serif;
    padding: 20px 20px 30px;
    letter-spacing: 0.3px;
    font-weight: normal;
    line-height: 1.5;
    text-align: left;
    // border-bottom: 6px solid red;
    // border-left: 6px solid #452F90;
    @media (max-width: $breakpoint-phone) {
      font-size: 18px;
      text-align: center;
      text-indent: 0;
      letter-spacing: 0;
    }
  }

  .contact-detail {
    display: inline-block;
    margin: 20px auto 0 auto;

    p {
      // letter-spacing: 0.3px;
      text-indent: 0;
      padding: 0;
      margin-top: 10px;
      display: flex;
      align-items: center;
      // justify-content: flex-start;

      span {
        color: #ed1865;;
        margin-left: 10px;
      }

      svg {
        margin-right: 10px;
      }
    }
  }

  ul {
    text-align: left;
    list-style-type: circle;
    margin-top: 40px;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.9px;
    line-height: 1.5;

  }

  li {
    margin: 0 20px 10px 40px;
    @media (max-width: $breakpoint-phone) {
      margin: 20px 10px 0 30px;
      font-size: 18px;
      letter-spacing: 0;
    }
  }

}

