@import './animation/rotation';
// @import './breakpoints';

.landing-header{
    z-index: 2;
    position: relative;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    padding: 0 1vw 0 25px;

    @media (max-width: $breakpoint-phone) {
        padding: 0 10px;
        // background-color: red;
    }

    .nssc-logo-container{
        position: relative;
        top: 0.5vh;
        // align-self: flex-start;
        #nssc-logo{
            height: 12vh;
            width: auto;   
            position: relative;
            
            
            @media (max-width: $breakpoint-phone) {
                // height: 70px;

                height: 10vh;
            }
            
            #outer-rotor{
                display: none;
                // animation: clockwise-rotate 5s forwards ease infinite;
                animation: my-clockwise-rotate-2 5s forwards ease-in-out infinite;

                @keyframes my-clockwise-rotate-2{
                    from{
                        transform: rotateZ(0);
                    }   
                    to{
                        transform: rotateZ(360deg);
                    }
                }
            }

            #inner-rotor{
                display: none;
                // animation: clockwise-rotate 5s forwards ease-in-out infinite;
                animation: my-clockwise-rotate 5s forwards ease infinite;

                @keyframes my-clockwise-rotate{
                    from{
                        transform: rotateZ(0)  ;
                    }   
                    to{
                        transform: rotateZ(360deg) ;
                    }
                }

                @media (max-width: $breakpoint-phone){
                    // display: none;
                    // animation: my-clockwise-rotate2 5s forwards ease infinite;

                    @keyframes my-clockwise-rotate2{
                        from{
                            transform: rotateZ(0) scale(1.2) ;
                        }   
                        to{
                            transform: rotateZ(360deg) scale(1.2) ;
                        }
                    }
                }

              
            }
        }
        // &:before{
        //     content: url(../imgs/nssc_icon.png);
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     z-index: 2;
        //     height: 7vh;
        //     width: 7vh;

        //     @media (max-width: $breakpoint-phone) {
        //         // font-size: 18px;

        //         // font-size: 2.7vh;
               
        //     }
        // }
        &:after{
            content: 'NSSC 2021';
            position: absolute;
            top: 4vh;
            // left: 12vh;
            left: 11vh;
            font-family: 'Orbitron', sans-serif;
            color: rgb(240, 238, 238);
            opacity: 0.9;
            font-weight: 300;
            font-size: 2.7vh;            

            @media (max-width: $breakpoint-phone) {
                // font-size: 18px;

                // font-size: 2.7vh;
                top: 2.8vh;
                left: 9vh;
            }
        }
    }
    .nssc-logo{
        // align-self: flex-start;
        // height: 70px;
        // width: 70px;
        position: absolute;
        // left: 2.5vh;
        // top: 1.5vh;

        top: 0.5vh;
        left: 2vh;
        height: 8vh;
        width: 8vh;
        // display: none;
        margin-top: 10px;

        // filter: drop-shadow(0 0 10px rgba(255,255,255,0.4));

        @media (max-width: $breakpoint-phone) {
        //   margin-top: 0.5vh;
            top: 0;
            left: 1vh;
            height: 7vh;
            width: 7vh;
            // filter: drop-shadow(0 0 5px rgba(255,255,255,0.4));
        }

        .nssc-logo-img{
            height: 100%;
            width: 100%;
        }
    }
}
