// @import './breakpoints';
$breakpoint-1200: 1200px;
// Scroll Bar 

// #orbits{
//   position: absolute;
//   z-index: 1;
//   left: 50%;
//   transform: translateX(-50%);
// }

@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');


.event-menu {
  // background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  background: url(../imgs/backgrounds/37.jpg);
  background-repeat: no-repeat;
  // background-position: center;
  // background-size: cover;
  background-attachment: fixed;
  // background-size: cover;
  // background-size: contain;
  // background-repeat: repeat-y;
  // height: 100%;
  // height: 100vh;
  overflow: hidden;

  @media (max-width: $breakpoint-phone) {
    padding: 0 0 80px 0;
    overflow: hidden;
    // height: auto;
  }

  // #back{
  //   // background-color: rgba(0, 0, 0, 0.5);
  //   // background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  //   // background-image: url(../imgs/backgrounds/background.jpg);
  //   opacity: 0.4;
  //   background-size: cover;
  //   background-repeat: repeat-y;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   height: 100%;
  //   width: 100%;
  //   z-index: 0;
  // }

  .event-menu-inner {
    background: radial-gradient(ellipse at bottom, rgba(27, 39, 53, 0.4) 0%, rgba(9, 10, 15, 0.4) 100%);
    padding-bottom: 100px;
    padding-top: 60px;

    @media (max-width: $breakpoint-phone) {
      padding: 60px 0 80px 0;
    }

  }

  .mine {
    background: url(../imgs/backgrounds/planet1.jpg);
    box-shadow: inset 8px 8px 10px rgba(253, 232, 247, 0.479),
    inset -19px -19px 70px rgba(0, 0, 0, 0.9),
    inset -5px -5px 8px rgba(0, 0, 0, 0.7),
    -5px -5px 10px 1px rgba(138, 138, 138, 0.5),
    5px 5px 30px 1px rgba(66, 14, 87, 0.5);
    opacity: 0.9;
  }


  .event-container {
    display: flex;
    justify-content: center;
    margin: 100px auto 0 auto;
    height: 350px;
    @media (max-width: $breakpoint-phone) {
      flex-direction: column;
      align-items: center;
      margin: 0 0 20px 0;
      padding: 30px 0;
      height: auto;
    }
    // max-width: 1500px;

    //   &:last-of-type{
    //       margin-bottom: 0;
    //   }

    //  &:first-of-type{
    //      margin-top: 100px;
    //  }

    .event-img {
      height: 200px;
      width: 200px;
      margin-right: 60px;
      margin-top: 20px;
      // @media (max-width: $breakpoint-1200){
      //   width: 300px;
      //   height: auto;
      // }
      @media (max-width: $breakpoint-phone) {
        width: 150px;
        height: auto;
        margin: 0;
      }
    }

    img#pc {
      display: block;
      @media (max-width: $breakpoint-phone) {
        display: none;
      }
    }

    img#mobile {
      display: none;
      @media (max-width: $breakpoint-phone) {
        display: inline-block;
      }
    }

    .event-info {
      /* margin-left: 40px; */
      max-width: 700px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;


      .event {
        /* overflow: hidden; */
        /* height: 200px;| */
        border-left: 6px solid #ed1865;
        padding: 0 20px 20px 20px;

        font-style: sans-serif;
        font-size: 18px;
        /* width:20%; */
        @media (max-width: $breakpoint-phone) {
          border: none;
          text-align: center;
          padding: 0;
          // display: flex;
          // flex-direction: column;
          // align-items: center;
        }

        h1 {
          text-align: center;
          // color:white;
          color: #ed1865;
          font-size: 60px;
          text-transform: uppercase;
          // font-weight: 600;
          font-weight: normal;
          letter-spacing: 2px;
          font-family: 'Bebas Neue', cursive;

          @media (max-width: $breakpoint-phone) {
            font-size: 40px;
            margin: 0 5px 20px;
          }
        }

        p {
          margin-top: 20px;
          line-height: 1.5;
          color: #fdf5ea;
          font-family: 'Roboto', sans-serif;

          @media (max-width: $breakpoint-phone) {
            font-size: 18px;
            // text-align: left;
            margin: 20px 0 30px;
            padding: 0 20px;
          }
        }

      }

      a {
        // width: 60%;
        margin: 0 auto;
      }

      .event-button {
        // width: 100%;
        /* position:absolute;
       top:450px;
       left:680px; */
        //   background-image: linear-gradient(-45deg,transparent 20px, #452F90 20px, #452F90 50);
        background-color: #452F90;
        border: none;
        color: white;
        //   padding: 15px 32px;
        padding: 15px 80px;
        text-align: center;
        text-decoration: none;
        /* display: inline-block; */
        font-size: 30px;
        font-style: italic;
        letter-spacing: 2px;
        /* margin: 4px 2px; */
        cursor: pointer;
        transition: transform 0.25s ease-out;
        font-family: 'Bebas Neue', cursive;
        clip-path: polygon(0 0, 100% 0, 100% 65%, 90% 100%, 0 100%);
        transition: transform 0.25s ease;
        @media (max-width: $breakpoint-phone) {
          font-size: 25px;
          padding: 10px 60px;
        }

        &:hover {
          background-image: linear-gradient(-45deg, #452F90 0%, #c32170 100%);
          transform: scale(1.1);
        }
      }
    }
  }

}




