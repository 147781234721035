
  
  @import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);

.About{

    // background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 1000%);
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    color: #fafafa!important;
    text-align:center!important;
    overflow: hidden;

   .about-inner{
        font-weight: 500;
        line-height: 1.5;
        text-align: center;
        min-height: 100%;
        padding: 0 2rem 4rem;

        @media (max-width: $breakpoint-phone) {
            padding: 4rem 1rem;
        }

        h1 {
            font-size:70px;
            color:#E1DBE9;
            // text-shadow: 2px 2px darkblue;
            // font-family:'source-sans-pro',sans-serif;
            font-family: 'Bebas Neue', cursive;
            font-style: italic; 
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 4px;
            // font-weight: normal;
            font-weight: 400;
            @media (max-width: $breakpoint-phone) {
                font-size: 50px;
            }
        }
        p{
            margin-top: 20px;
            line-height: 1.5;
            color:#fdf5ea;
            font-family: 'Roboto', sans-serif;

            @media (max-width: $breakpoint-phone) {
              font-size: 18px;
              // text-align: left;
              margin: 20px 0 30px;
              padding: 0 20px;
            }  
        }
        .about-content{
            
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
            padding: 40px 40px 40px 40px;
            max-width: 1200px;
            margin: auto;

            @media (max-width: $breakpoint-phone) {
                padding: 40px 15px;
            }
        }
   }
}