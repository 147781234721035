@import './animation/rotation';
 @import './breakpoints';


.background-circle{
    // margin-top: 70px;
    // margin-bottom: 60px;
    // position: absolute;
    // left: 0;
    // top: 0;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%,-50%);
    // overflow: hidden;
    text-align: center;
    flex: 2;
    // position: fixed;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 100%;
    // z-index: 2000;

    // height: 100vh;
    // padding-top: 12vh;
    // padding-bottom: 10vh;
    // margin-bottom: -80px;

    @media (max-width: $breakpoint-phone) {
        // padding-top: 0;
        position: absolute;
        height: 100vh;
        width: 100vw;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        align-items: center;
        z-index: 0;
    }

    h1{
        // display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 100;
        transform: translate(-50%,-50%);
        // font-family: 'Orbitron', sans-serif;
        // font-family: 'Josefin Sans', sans-serif;
        font-family: techfont;
        color: rgb(177, 176, 176);
        font-weight: 400;
        // font-size: 3.4vh;
        font-size: 4vh;
        text-transform: uppercase;
        // text-align: center;
        letter-spacing: 2px;
        width: 100%;
        line-height: 1.5;
        text-shadow: 0 0 5px rgb(177, 21, 224);
        // @media (max-width: $breakpoint-tablet) {
        //     display: none;
        // }

        transform-origin: center;
        animation: text 7s infinite ease,title-animate 7s ease-in-out infinite;

        @keyframes text{
            0%{
                letter-spacing: 0;
            }
            50%{
                letter-spacing: 2px;
            }
            100%{
                letter-spacing: 0;
            }
        }

        @keyframes title-animate{
            0%{
                transform:  translate(-50%, -60%);
            }
            50%{
                transform:  translate(-50%, -40%);
            }
            100%{
                transform: translate(-50%,-60%);
            }
        }

        @media (max-width: $breakpoint-phone) {
            display: none;
            font-size: 28px;
        }
    }  
}
#background-circle{
    overflow: visible;
    height: 100%;
    width: auto;
    z-index: 100;
    position: relative;

    // transform: scale(0.7);
    // transform: translate(-40%,6%) scale(0.45);
    // opacity: 0.6;
    // display: none;
    
    // width: 100%;
    // transform: scale(1.3);

    @media (max-width: 1350px){
        height: 100%;
        width: 100%;
    }

    @media (max-width: $breakpoint-tablet) {
        overflow: hidden;
        // height: auto;
        // width: 100%;
    }
    
    
    @media (max-width: $breakpoint-phone) {
    //    position: fixed;
        overflow: hidden;
        z-index: 0;

        // height: auto;
        // width: 600px;
        // width: 600px;

        height: auto;
        width:100%;
    }
    @media (max-width: $breakpoint-small-phone) {
        // height: 500px;
        // width: 500px;
    }

    // &:hover + h1{
    //     // display: none;
    // }

    &:hover #inner-rotor{
        // animation: gyroscope3 10s both ease infinite;
        animation: clockwise-rotate 12s forwards ease-in-out infinite;

        @media (max-width: $breakpoint-tablet) {
            animation: clockwise-rotate 20s forwards linear infinite;
        }
    }
    &:hover #outer-rotor{

        animation: anticlockwise-rotate 20s forwards ease-in-out infinite;

        @media (max-width: $breakpoint-tablet) {
            animation: anticlockwise-rotate 20s forwards ease-in-out infinite;
        }
    }
    &:hover #inner{

        animation:none;
        @media (max-width: $breakpoint-tablet) {
            animation: none;     
        }
    }
    &:hover #innermost{

        animation:none;
        @media (max-width: $breakpoint-tablet) {
            animation: none;     
        }
    }

    #inner-rotor{

        animation: clockwise-rotate-1 10s both ease infinite;
        opacity: 0.2;   

        @media (max-width: $breakpoint-tablet) {
            animation: clockwise-rotate 20s forwards linear infinite;     
        }
        @media (max-width: $breakpoint-phone) {
            animation: clockwise-rotate 20s forwards linear infinite;     
        }        
        
    }

    #outer-rotor{
        animation: gyroscope4 10s both ease infinite;
        opacity: 0.7;
    }

    #inner{
        // animation: gyroscope2 10s both ease infinite;
        transform-origin: center;
        transform-box: fill-box;
        opacity: 0.6;
        animation: gyroscope2 10s both ease infinite;
    }

    #innermost{
        // animation: gyroscope1 10s both ease infinite;
        animation: gyroscope1 10s both ease infinite;
        transform-origin: center;
        transform-box: fill-box;
        opacity: 0.6;
    }

    @keyframes gyroscope1{
        0%{
            transform: rotate3d(1,1,0,0deg);
        }
        50%{
            transform: rotate3d(-1,0,0,180deg);
        }
        100%{
            transform: rotate3d(1,1,0,360deg);
        }
    }
    @keyframes gyroscope2{
         0%{
            // transform: rotate3d(1,1,0,0deg);
            transform: rotateX(0deg) rotateY(0deg);
        }
        50%{
            // transform: rotate3d(-1,-1,0,180deg);
            transform: rotateX(-180deg) rotateY(180deg);
        }
        100%{
            // transform: rotate3d(1,1,0,360deg);
            transform: rotateX(0deg) rotateY(0deg);
        }
      
    }
    @keyframes gyroscope3{
        //  0%{
        //     transform: rotate3d(1,1,0,0deg);
        // }
        // 50%{
        //     transform: rotate3d(0,-1,0,180deg);
        // }
        // 100%{
        //     transform: rotate3d(1,1,0,360deg);
        // }
    }
    @keyframes gyroscope4{
        0%{
            // transform: rotate3d(1,1,0,0deg);
            transform: rotateX(0deg) rotateY(0deg);
        }
        25%{
            // transform: rotate3d(1,-1,0,90deg);
        }
        50%{
            // transform: rotate3d(1,0,0,0deg);
            transform: rotateX(180deg) rotateY(180deg);
        }
        75%{
            // transform: rotate3d(1,-1,0,180deg);
        }
        100%{
            // transform: rotate3d(1,1,0,360deg);
            transform: rotateX(0deg) rotateY(0deg);
        }
    }

    @keyframes clockwise-rotate-1{
        0%{
            transform: rotateZ(0deg) scale(1);
        }
        50%{
            transform: rotateZ(180deg) scale(0.7);
        }
        100%{
            transform: rotateZ(360deg) scale(1);
        }
    }
}
