
.rotation{
    transform-origin: center;
    transform-box: fill-box;
}
@keyframes clockwise-rotate{
    from{
        transform: rotateZ(0deg);
    }
    to{
        transform: rotateZ(360deg);
    }
}
@keyframes anticlockwise-rotate{
    from{
        transform: rotateZ(0deg);
    }
    to{
        transform: rotateZ(-360deg);
    }
}